@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap');

@font-face {
  font-family: 'dianasfont';
  src:  url('font/thabit-webfont.woff2') format('woff2'),
        url('font/thabit-webfont.woff') format('woff');
}

h1, h2, h3 {
  margin: 0;
}

.resume {
  width: 100%;
  height: 80vh;
}

.heroOne, .heroTwo, .heroThree {
  display: flex;
  flex-wrap: wrap;
margin: 0 auto;
  justify-content: space-between;
  align-items: stretch;
  width: 80%;
  flex-direction: row;
  
}

.heroItem {
  position: relative;
  width: 50%;
  margin-bottom: 1em;
  margin: 0 auto;
}

.resHeroItem {
  position: relative;
  width: 50%;
  margin-bottom: 1em;
  align-self: flex-end;
  margin: 0 auto;
}

.abtHeroItem {
  position: relative;
  width: 50%;
  margin-bottom: 1em;
  align-self: center;
}


.verticalCenter {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}

.dianaYell {
  width: 60%;
}

* {
  /*(font-family: 'Helvetica', 'Arial', sans-serif;*/
  font-family: 'dianasfont', Fallback, sans-serif;

}

html, body {
  
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

h1 {
    font-size: 2em;
    color: black;
    margin: 0;
}
h2 {
  margin: 0;
}
p {
  margin: 0;
}

.navText {
  font-size: 1.4em;
  width: 100%;
  margin-top: .5em;
  padding: 0;
  background-color: #D9D9D9;
}

.coverImageHeader {
  font-size: 2em;
  width: auto;

  padding: .5em;
  background-color: #D9D9D9;
position: absolute;
width: auto;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: black;
}



.submitText {
  margin: 0 auto;
  text-align: center;
  width: 20%;
  margin-top: .5em;
  padding: 0;
  background-color: #D9D9D9;
}

.input {
  width: 100%;
  height: 50vh;
}


body {
  background-color: #85cddf;
  margin:0;
  padding:0;

}




.backButton {
  position: fixed;
  top: 2em;
  left: 2em;
  width: 15%;
  z-index: 997;
}

.specialBackButton {
    position: fixed;
    top: 2em;
    left: 2em;
    width: 15%;
    z-index: 999;

}

.pageContainer {
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: hidden; 
  overflow-x: hidden;  
  overflow: hidden;
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
  animation: 2s ease 0s normal forwards 1 fadeIn;
  opacity: 0;
}

.secondaryPageContainer {
  width: auto;
  background-color: transparent;
  max-width: 3000px;
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
  animation: 2s ease 0s normal forwards 1 fadeIn;
  opacity: 0;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
    animation: 2s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
}

.clickMe {
  position: absolute;
  top: 8.5em;
  left: 0; 
  right: 22em; 
  padding-right: 0;
  margin-left: auto; 
  margin-right: auto; 
  width: 10%;
  animation: shake .7s;
  animation-iteration-count: infinite;
}

.logo {
  width: 15%;
  position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
top: 0;
text-align: center;
-webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
animation: 2s ease 0s normal forwards 1 fadeIn;
opacity: 0;
}

.instaFooter {
  width: 10%;
  padding-bottom: 1em;
}

.bubblePic {
  width: 100%;
  background-color: #fff;
/*box-shadow: 0px 0px 20px 5px #0ff;*/
}

.modalPic {
  height: 70vh;
  width: auto;
  margin: 0 auto;
}

.myBubbleUI {
  
  margin: 0 auto;
  width: auto;
  max-width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.interestBubbleUI {
  width: 100%;
  max-width: 500px;
  height: 350px;
  background-color: transparent;
}

.childComponent {
  width: 100%; /* width expands to fit bubble */
  height: 100%; /* width expands to fit bubble */
  border-radius: 50%; /* rounded border forms a circle */
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.ReactModal__Content--after-open::-webkit-scrollbar {
  display: none;
} 

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
    animation: 2s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}



.ReactModal__Overlay--before-close{
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
    animation: 2s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
}




.masonicGridContainer {

    width: 100%; /* width expands to fit bubble */
    height: 100%; /* width expands to fit bubble */
    border-radius: 50%; /* rounded border forms a circle */
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: flex-start;
    background-color: transparent;
}

.masonicImage {
  width: 100%;
  height: inherit;
  margin: 0 auto;
}

.masonicGridItem {
  width: 33.333333%;
}

img {
  max-width: 100%;
  display: block;
}

.video {
  width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 0px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255,255,255,.5);
  padding: .2em .5em;
  justify-self: start;
}

.container {
  column-count: 3;
  column-gap: 0px;
}

.ReactModal__Content--after-open {
padding: 0;

}

.ReactModal__Overlay {
  
}

.ReactModal__Overlay--after-open {
 
}
.ReactModal__Content {
  padding: 0 !important;
  /*background-color: black !important;*/
  
  }

.centerMe {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
  animation: 2s ease 0s normal forwards 1 fadeIn;
  opacity: 0;
}

.galleryContainer { /**/
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
  
}

.arrow {
  margin: 1em;
  z-index: 1;

  width: 33%;
}

.navigationContainer { /**/
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
}

.navigationCard {
  margin: .5em;
  z-index: 1;

  width: 40%;
}

.navigationCardImage {
  width: 100%;
}



.galleryImage {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  
  }

  .imageContainer {
    margin: 0 auto;
height: 60vh;
overflow: hidden !important;}

.formContainer {
  margin: 0 auto;
  width: 80%;
  background-color: transparent;
}

.chatBox {
  margin: 0 auto;
  width: 60%;

}

.nameBox {
  margin: 0 auto;
  width: 60%;
  margin-bottom: 1em;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

}

.qaBox {
  width: 30%;
}

.qaDiv {
  top: 1em;
  height:20px;
 
  width: 30%;
  position: absolute;
  
  left: 0;
  right: 0em;
  margin-right: auto;
  margin-left: auto;
  
}

.qa-block {
  margin-left: 1em;
 height: 1em;
  width: 90%;
  position: absolute;
  left: 0;
  right: 0em;
  top: 0em;
  height: inherit;
  background-color: transparent;
  border: none;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
}

.text-block {
  height: 200px !important;
  width: 55%;
  position: absolute;
  left: 0;
  right: 0;
  top: 1em;
  height: inherit;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

.nameDiv {
  top: 1em;
  height: 20px;
  background-color: transparent;
  width: 60%;
  position: absolute;
  left: 0;
  right: 0em;
  margin-right: auto;
  margin-left: auto;
  
}

.name-block {
  margin-left: 1em;
 height: 1em;
  width: 55%;
  position: absolute;

  right: 0em;
  top: 0.2em;
  height: inherit;
  border: none;
  background-color: transparent;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  
}

.pageHeaderText {
  font-size: 2.5em;
  margin: 0 auto;
  width: 60%;
}

.spacing {
  height: 1.5em;
}

.messageContainer {
  position: relative;
}

input:focus {
  outline: none !important;
}

textarea:focus {
  outline: none !important;
}

.blogContainer {
  margin-top: 8em !important;
  margin: 0 auto;
  width: 40%;
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
  animation: 2s ease 0s normal forwards 1 fadeIn;
  opacity: 0;
}

.resumeContainer {
  margin-top: 8em !important;
  margin: 0 auto;
  width: 60%;
}

.blurMe{
  width: 100%;
  filter: blur(2px);
  -webkit-filter: blur(2px);

}

.overlayMe {
  background: rgba(50, 70, 80, .7);
}

.coverImage {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;

}
.newCoverImage {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;

}

.resumeImage {
  margin: 0 auto;
  position: relative;
  width: 100%;
  opacity: 100%;


}

.overlay {
  /*background: rgba(50, 70, 80, .1);*/
 
  width: 80%;
  height: 100%;
 }


 .qs {
  width: 50%;
 }



@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media only screen and (min-width: 1301px) and (max-width: 1500px)
{


.qa-block {
  height: 10px !important;
 

}
  
  .text-block {

    height: 180px !important;
  }
  
  .clickMe {
    position: absolute;
    top: 6.5em;
    left: 0; 
    right: 15em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 10%;
  }

  
}

@media only screen and (min-width: 1201px) and (max-width: 1300px)
{
  .qa-block {
    height: 10px !important;
   
  
  }

  .text-block {
    height: 160px !important;
  }
  .clickMe {
    position: absolute;
    top: 6em;
    left: 0; 
    right: 13.5em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 10%;
  }

  .navigationContainer { /**/

    width: 80%;
  }
  
  .navigationCard {
    margin: .3em;
    z-index: 1;
  
    width: 45%;
  }
  
  .navigationCardImage {
    width: 100%;
  }
  .navText {
    font-size: 1.7em;
  }

  .coverImageHeader {
    font-size: 1.7em;
    width: 60%;
  
    padding: .5em;
  }

  .submitText {

    width: 45%;
  }

  
}

@media only screen and (min-width: 1001px) and (max-width: 1200px)
{

  .text-block {
    width: 55%;
    height: 150px !important;
  }

  .name-block {
    height: 10px;
  }

  
  .clickMe {
    position: absolute;
    top: 5em;
    left: 0; 
    right: 11em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 10%;
  }

  .navigationContainer { /**/

    width: 80%;
  }
  
  .navigationCard {
    margin: .3em;
    z-index: 1;
  
    width: 45%;
  }
  
  .navigationCardImage {
    width: 100%;
  }
  .navText {
    font-size: 1.7em;
  }

  .coverImageHeader {
    font-size: 1.7em;
    width: 60%;
  
    padding: .5em;
  }

  .submitText {

    width: 45%;
  }


  
}


@media only screen and (min-width: 601px) and (max-width: 1000px)
{
  

  .resume {
    width: 100%;
    height: 55vh;
  }

  .abtHeroItem {
    position: relative;
    width: 60%;
    margin-bottom: 1em;
    align-self: center;
    margin: 0 auto;
  }
  

  .heroOne, .heroTwo, .heroThree {
    width: 100%;
  }

  .dianaYell {
    width: 100%;
    display: none;
  }
  .blogContainer {
    margin-top: 8em !important;
    margin: 0 auto;
    width: 80%;
  }

  .resumeContainer {
    margin-top: 8em !important;
    margin: 0 auto;
    width: 80%;
  }

  .coverImage {
    margin: 0 auto;
    position: relative;
    width: 80%;
  }

  .newCoverImage {
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  .chatBox {
    margin: 0 auto;
    width: 80%;
  }

  .nameBox {
    margin: 0 auto;
    width: 80%;
    margin-bottom: 1em;
  }

  .qaBox {
    width: 60%;
  }

  .qaDiv {
    width: 60%;
  }

  

  .nameDiv {
    top: .5em;
    width: 80%;
  }

  .text-block {
    width: 70%;
    height: 170px !important;
  }

  .pageHeaderText {
    font-size: 1.8em;
    margin: 0 auto;
  width: 80%;
  }

  .navigationContainer { /**/

    width: 80%;
  }
  
  .navigationCard {
    margin: .3em;
    z-index: 1;
  
    width: 45%;
  }
  
  .navigationCardImage {
    width: 100%;
  }
  .navText {
    font-size: 1.7em;
  }

  .coverImageHeader {
    font-size: 1.7em;
    width: 60%;
  
    padding: .5em;
  }

  .submitText {

    width: 45%;
  }

  @media only screen and (min-width: 601px) and (max-width: 800px)
{
  
  .clickMe {
    position: absolute;
    left: 0; 
    right: 16em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 20%;
  }

  .logo {
    width: 30%;
  }

  .instaFooter {
    width: 20%;
  }
  
}

@media only screen and (min-width: 801px) and (max-width: 1000px)
{

  .clickMe {
    position: absolute;
    top: 10em;
    left: 0; 
    right: 27em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 30%;
  }

  .logo {
    width: 40%;
  }

  .instaFooter {
    width: 30%;
  }
  
}

  
  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: space-around;
    width: 100%;
    
  }

  .galleryImage {
    width: 100%;
    position: absolute;
    
    
  
    
    }
  
    .imageContainer {
  height: 70vh;}
  

  

  .backButton {
    position: fixed;
    top: 1em;
    left: 1em;
    width: 45%;
  }
  .specialBackButton {
    position: fixed;
    top: 1em;
    left: 1em;
    width: 45%;
  }
  

  .container {
    column-count: 2;
    column-gap: 0px;
  }

  
  .modalPic {
    width: 80%;
    height: auto;
  }
  .pageContainer {
    width: 100%;
  }

  

  .centerMe {
    width: 70%;
  }
  
}


@media only screen and (min-width: 0px) and (max-width: 600px)
{

  .qs {
    width: 80%;
  }


  .resume {
    width: 100%;
    height: 55vh;
  }

  .abtHeroItem {
    position: relative;
    width: 90%;
    margin-bottom: 1em;
    align-self: center;
    margin: 0 auto;
  }

  h2 {
    font-size: 1.3em;
  }

  h1 {
    font-size: 1.6em;
  }

  h1 {

  }


  .heroItem {
    position: relative;
    width: 50%;
    margin-bottom: 1em;
  }

  .heroOne, .heroTwo, .heroThree {
    width: 100%;
  }
  
  .dianaYell {
    width: 100%;
    display: none;
  }

  .blogContainer {
    margin-top: 5em !important;
    margin: 0 auto;
    width: 80%;
  }

  .resumeContainer {
    margin-top: 5em !important;
    margin: 0 auto;
    width: 90%;
  }

  
  

  .spacing {
    height: 1em;
  }
  .chatBox {
    margin: 0 auto;
    width: 90%;
  }

  .nameBox {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 1em;
  }

  .qaBox {
    width: 70%;
  }

  .qaDiv {
    width: 70%;
  }



  .nameDiv {
    top: .5em;
    width: 90%;
  }

  .text-block {
    width: 80%;
    height: 140px !important;
  }

  .pageHeaderText {
    font-size: 1.8em;
    margin: 0 auto;
  width: 90%;
  }

  .navigationContainer { /**/

    width: 100%;
  }
  
  .navigationCard {
    margin: .3em;
    z-index: 1;
  
    width: 45%;
  }
  
  .navigationCardImage {
    width: 100%;
  }
  .navText {
    font-size: 1.2em;
  }

  .coverImageHeader {
    font-size: 1.2em;
    width: 70%;
  
    padding: .5em;
  }
  .submitText {

    width: 45%;
  }

  

  
  @media only screen and (min-width: 501px) and (max-width: 600px)
  {
    .clickMe {
      position: absolute;
      top: 5em;
      left: 0; 
      right: 12em; 
      padding-right: 0;
      margin-left: auto; 
      margin-right: auto; 
      width: 20%;
    }
  
    .logo {
      width: 30%;
    }
  
    .instaFooter {
      width: 20%;
    }
    
  }
  @media only screen and (min-width: 351px) and (max-width: 500px)
{
  .clickMe {
    position: absolute;
    top: 6em;
    left: 0; 
    right: 14em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 30%;
  }

  .logo {
    width: 50%;
  }

  .instaFooter {
    width: 30%;
  }
  
}

@media only screen and (min-width: 301px) and (max-width: 350px)
{
  .clickMe {
    position: absolute;
    top: 6em;
    left: 0; 
    right: 12em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 30%;
  }
  .logo {
    width: 50%;
  }

  .instaFooter {
    width: 30%;
  }
  
}

@media only screen and (min-width: 0px) and (max-width: 300px)
{
  

  .qa-block {
    height: 10px;
  }
  .text-block {
    height: 100px !important;
  }

  .name-block {
    margin-left: 1em;
    height: 10px;
  }
  .clickMe {
    position: absolute;
    top: 4.5em;
    left: 0; 
    right: 10em; 
    padding-right: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 30%;
  }
  .logo {
    width: 50%;
  }

  .instaFooter {
    width: 30%;
  }
  
}

  .centerMe {
    width: 100%;
  }


  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: space-around;
    width: 100%;
    
  }

  .galleryImage {
    width: 100%;
    position: absolute;
    
    
  
    
    }
  
    .imageContainer {
      width: 100%;
  height: 50vh;}
  

  
  

  .backButton {
    position: fixed;
    top: 1em;
    left: 1em;
    width: 45%;
  }

  .specialBackButton {
    position: fixed;
    top: 1em;
    left: 1em;
    width: 45%;
  }


  

  .container {
    column-count: 2;
    column-gap: 0px;
  }

  
  .modalPic {
    width: 80%;
    height: auto;
  }
  .pageContainer {
    width: 100%;
  }

  
  
}
